import {AssetMap} from "types";

export const assets: AssetMap[] = [
    {
        targetIndex: 0,
        glbModelSrc: '/models/ZONE_accenture-tube.glb'
    },
    {
        targetIndex: 1,
        glbModelSrc: '/models/ZONE_time-wrap.glb'
    },
    {
        targetIndex: 2,
        glbModelSrc: '/models/ZONE_haptic-fix.glb'
    },
    {
        targetIndex: 3,
        glbModelSrc: '/models/ZONE_vr.glb'
    },
    {
        targetIndex: 4,
        glbModelSrc: '/models/bacsd-fp.glb'
    },
    {
        targetIndex: 5,
        glbModelSrc: '/models/bacls-hbps.glb'
    },
    {
        targetIndex: 6,
        glbModelSrc: '/models/bublp-lm.glb'
    },
    {
        targetIndex: 7,
        glbModelSrc: '/models/bcp-acqua.glb'
    },
    {
        targetIndex: 8,
        glbModelSrc: '/models/ZONE_digital-gallery.glb'
    },
    {
        targetIndex: 9,
        glbModelSrc: '/models/brlp-nht.glb'
    },
    {
        targetIndex: 10,
        glbModelSrc: '/models/bcp-c2d.glb'
    },
    {
        targetIndex: 11,
        glbModelSrc: '/models/bcp-rcd.glb'
    },
    {
        targetIndex: 12,
        glbModelSrc: '/models/bug-eil.glb'
    },
    {
        targetIndex: 13,
        glbModelSrc: '/models/buv-vbt.glb'
    },
    {
        targetIndex: 14,
        glbModelSrc: '/models/bapa-cb.glb'
    },
    {
        targetIndex: 15,
        glbModelSrc: '/models/blt-acd.glb'
    },
    {
        targetIndex: 16,
        glbModelSrc: '/models/blt-ccls.glb'
    },
    {
        targetIndex: 17,
        glbModelSrc: '/models/buvw-l2b.glb'
    },
    {
        targetIndex: 18,
        glbModelSrc: '/models/buvw-3tec.glb'
    },
    {
        targetIndex: 19,
        glbModelSrc: '/models/ZONE_typing-tutor.glb'
    },
    {
        targetIndex: 20,
        glbModelSrc: '/models/perfume.glb', // Added on Mar 10, 2025
        position: [35, -5, 0], // Shift to the right, down, and forward
        scale: 22 // 30 is sorta 100% of the size of the model
    }
];