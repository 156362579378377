import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { AssetMap } from 'types';

interface StaticAssetViewerProps {
    asset: AssetMap;
}

const StaticAssetViewer: React.FC<StaticAssetViewerProps> = ({ asset }) => {
    const { glbModelSrc, position, scale } = asset;
    const mountRef = useRef<HTMLDivElement>(null);
    const modelRef = useRef<THREE.Group | null>(null);  // Track the current model
    const rendererRef = useRef<THREE.WebGLRenderer | null>(null);
    const sceneRef = useRef<THREE.Scene | null>(null);
    const cameraRef = useRef<THREE.PerspectiveCamera | null>(null);

    useEffect(() => {
        let animationFrameId: number;

        const mount = mountRef.current;
        if (!mount) return;

        if (!rendererRef.current) {
            // Create scene, camera, and renderer
            const scene = new THREE.Scene();
            const aspect = mount.clientWidth / mount.clientHeight;
            const camera = new THREE.PerspectiveCamera(45, aspect, 0.1, 1000);
            const renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true });
            renderer.setSize(mount.clientWidth, mount.clientHeight);
            renderer.setPixelRatio(window.devicePixelRatio);
            renderer.setClearColor(0x000000, 0); // Make the background transparent

            // Append renderer to DOM and store it in the ref
            mount.appendChild(renderer.domElement);
            rendererRef.current = renderer;
            sceneRef.current = scene;
            cameraRef.current = camera;

            // Add lights
            const ambientLight = new THREE.AmbientLight(0xffffff, 2.5);
            scene.add(ambientLight);
            const directionalLight = new THREE.DirectionalLight(0xffffff, 2);
            directionalLight.position.set(5, 10, 7.5);
            scene.add(directionalLight);

            const animate = () => {
                animationFrameId = requestAnimationFrame(animate);
                renderer.render(scene, camera);
            };

            animate();
        }

        const loader = new GLTFLoader();
        const dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderPath('https://www.gstatic.com/draco/versioned/decoders/1.5.7/');
        loader.setDRACOLoader(dracoLoader);

        const scene = sceneRef.current;
        const camera = cameraRef.current;

        // Dispose of the previous model before loading a new one
        if (modelRef.current && scene) {
            scene.remove(modelRef.current);
            modelRef.current.traverse((object) => {
                if ((object as THREE.Mesh).isMesh) {
                    const mesh = object as THREE.Mesh;
                    mesh.geometry.dispose();
                    if (Array.isArray(mesh.material)) {
                        mesh.material.forEach((material) => material.dispose());
                    } else {
                        mesh.material.dispose();
                    }
                }
            });
            modelRef.current = null;
        }

        loader.load(
            glbModelSrc,
            (gltf) => {
                modelRef.current = gltf.scene;
                const model = modelRef.current;

                // Compute the bounding box of the model
                const box = new THREE.Box3().setFromObject(model);
                const size = box.getSize(new THREE.Vector3());
                const center = box.getCenter(new THREE.Vector3());

                // Dynamically calculate the scale based on viewport size
                const maxDimension = Math.max(size.x, size.y, size.z);
                const viewportHeight = mount.clientHeight;
                const viewportWidth = mount.clientWidth;

                // Calculate default scale factor based on viewport size
                const defaultScaleFactor = 0.9 * Math.min(viewportWidth / size.x, viewportHeight / size.y);
                
                // Use custom scale if provided, otherwise use default scale factor
                const scaleFactor = scale !== undefined ? scale : defaultScaleFactor;
                model.scale.set(scaleFactor, scaleFactor, scaleFactor);

                // Apply custom position if provided in the asset, otherwise use default positioning
                if (position) {
                    const [x, y, z] = position;
                    // Apply the custom position offset
                    model.position.set(-center.x + x, -center.y + y, -center.z + z);
                } else {
                    // Default positioning
                    model.position.set(-center.x, -center.y, -center.z);
                }

                // Adjust the camera distance to ensure the model fits within the screen
                camera?.position.set(0, 0, maxDimension * 33);

                scene?.add(model);
            },
            undefined,
            (error) => {
                console.error('An error occurred while loading the model', error);
            }
        );

        const onWindowResize = () => {
            if (!mount || !camera || !rendererRef.current) return;
            const aspect = mount.clientWidth / mount.clientHeight;
            camera.aspect = aspect;
            camera.updateProjectionMatrix();
            rendererRef.current.setSize(mount.clientWidth, mount.clientHeight);
        };
        window.addEventListener('resize', onWindowResize, false);

        return () => {
            window.removeEventListener('resize', onWindowResize);
            cancelAnimationFrame(animationFrameId);
            if (rendererRef.current && mount) {
                mount.removeChild(rendererRef.current.domElement);
                rendererRef.current.dispose();
            }
        };
    }, [glbModelSrc, position, scale]);

    return <div ref={mountRef} style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 10 }} />;
};

export default StaticAssetViewer;